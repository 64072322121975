

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Aboutus = () => (
    <Layout>
        <SEO title="Contact us" />
        <div className="grid-container narrow">
            <section className="typography text-center">
                <h1 class="text-shadow pink">About Melbourne Institute</h1>
                <p>The Melbourne Institute: Applied Economic & Social Research at The University of Melbourne is Australia’s pre - eminent economic and social policy research institution.For more than 55 years the Melbourne Institute’s world - class economists have undertaken high quality, 
                    independent and impartial applied research, providing an evidence base for effective policy reform.</p>
                <p>It is internationally renowned for managing the Household, Income and Labour Dynamics in Australia(HILDA) 
                    Survey and the Medicine in Australia: Balancing Employment and Life(MABEL) Survey, as well as for its long running analysis of the economy and social welfare conditions in Australia.</p>
                <p>Each year the Melbourne Institute manages the annual HILDA Survey on behalf of the Australian Government’s 
                    Department of Social Services.The HILDA Survey is a household - based panel study that collects valuable 
                    information about economic and personal wellbeing, labour market dynamics and family life.</p>
                <p>Started in 2001, the HILDA Survey now tracks more than 17 500 people in 9500 households across the country 
                    to provide insights into how living in Australia has changed over time.</p>

            </section>
        </div>
    </Layout>
)

export default Aboutus

